import Vue from "vue";
import App from "./App.vue";
import CookiePolicy from "./CookiePolicy.vue";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import VueRouter from "vue-router";
import "./assets/css/_globals.css";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

Vue.mixin({
  created: function () {
    this.gsap = gsap;
  },
});

Vue.config.productionTip = false;
Vue.config.ignoredElements = ["video-js"];

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    { path: "/cookie-policy", component: CookiePolicy },
    {
      path: "/",
      component: App,
    },
    {
      path: "*",
      component: App,
    },
  ],
});

new Vue({
  router,
}).$mount("#app");
