<template>
  <div class="footer">
    <address>
      Think Outside the Shell is a trademark of Seqirus UK Limited or its
      affiliates.<br />
      Seqirus USA Inc., 25 Deforest Avenue, Summit, NJ 07901, USA <br />
      ©2023 Seqirus USA Inc. September 2023 USA-SEQ-23-0101 <br />
      For US Healthcare Professional Use Only &nbsp; | &nbsp; <router-link id="cookie-policy" to="cookie-policy">Cookie Policy</router-link>
    </address>
    <a id="sequirus-logo-link" href="https://www.flu360.com" target="_blank">
      <img alt="White Seqirus logo" src="../assets/images/logo-white.png" />
    </a>
  </div>
</template>

<script>
export default {
  name: "RedFooter"
};
</script>

<style lang="scss" scoped>
.footer {
  background-color: #eb181b;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;

  #cookie-policy {
    padding-left: 0;
    font-weight: 400;
    background: transparent;
    color: #ffffff;
    border: none;
    display: inline-block;
    text-decoration: none;
    margin-bottom: 1rem;
    
    &:hover {
      text-decoration: underline;
      background: transparent;
    }
  }

  address {
    font-size: 1rem;
    color: #ffffff;
    font-weight: 400;
    text-align: left;
    font-style: normal;
    margin: 2rem 0;
    padding-left: 4.5rem;
    font-family: "Campton-Book", sans-serif;
  }

  #sequirus-logo-link {
    margin: 2rem 2rem;
    padding-right: 4.5rem;
  }

  @media screen and (max-width: 828px) {
    address {
      padding-left: 2rem;
      font-size: 12px;
    }

    #sequirus-logo-link {
      padding-right: 2rem;
      img {
        max-width: 5rem;
      }
    }
  }
}
</style>
