<template>
  <section
    class="strain-mismatch"
    id="what"
    aria-labelledby="strain-mismatch-title"
    ref="strainMismatch"
  >
    <div class="parallax-container" ref="parallaxContainer">
      <div class="parallax-bg parallax-bg--1" data-speed="0.25"></div>
      <div class="parallax-bg parallax-bg--2" data-speed="0.075"></div>
      <div class="parallax-bg parallax-bg--3" data-speed="0.125"></div>
    </div>
    <h2 id="strain-mismatch-title" class="screen-reader-text">
      Strain Mismatch
    </h2>
    <!-- <div class="indicator" ref="indicator">
      <span class="number current" v-text="formattedActiveSlide">01</span>
      <svg
        version="1.1"
        viewBox="0 0 24 169"
        width="22"
        height="155"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g ref="circles" fill="none" fill-rule="evenodd">
          <circle cx="12" cy="4" r="3.5" opacity=".4" stroke="#5D6F7B" />
          <circle cx="12" cy="26" r="5.5" opacity=".6" stroke="#5D6F7B" />
          <circle
            cx="12"
            cy="52"
            r="7"
            opacity=".8"
            stroke="#5D6F7B"
            stroke-width="2"
          />

          <circle cx="12" cy="83" r="9.5" stroke="#EB181B" stroke-width="3" />
          <circle
            cx="12"
            cy="114"
            r="7"
            opacity=".8"
            stroke="#5D6F7B"
            stroke-width="2"
          />
          <circle cx="12" cy="140" r="5.5" opacity=".6" stroke="#5D6F7B" />
          <circle cx="12" cy="162" r="3.5" opacity=".4" stroke="#5D6F7B" />
          <circle cx="12" cy="173" r="3.5" opacity=".4" stroke="#5D6F7B" />
          <circle cx="12" cy="195" r="5.5" opacity=".6" stroke="#5D6F7B" />
          <circle
            cx="12"
            cy="221"
            r="7"
            opacity=".8"
            stroke="#5D6F7B"
            stroke-width="2"
          />
          <circle cx="12" cy="252" r="9.5" stroke="#EB181B" stroke-width="3" />
          <circle
            cx="12"
            cy="283"
            r="7"
            opacity=".8"
            stroke="#5D6F7B"
            stroke-width="2"
          />
          <circle cx="12" cy="309" r="5.5" opacity=".6" stroke="#5D6F7B" />
          <circle cx="12" cy="331" r="3.5" opacity=".4" stroke="#5D6F7B" />
        </g>
      </svg>
      <span class="number total">05</span>
    </div> -->
    <ul class="content">
      <span class="scroll-hint" ref="scrollHint">
        Scroll Down
        <svg width="15" height="18" xmlns="http://www.w3.org/2000/svg">
          <g fill="#2A3237" fill-rule="evenodd">
            <path
              d="M.088 10.16a1.248 1.248 0 0 1 2.13-.882l5.046 5.045 4.942-4.942a1.248 1.248 0 0 1 1.765 1.765L8.146 16.97a1.249 1.249 0 0 1-1.764 0L.453 11.042a1.245 1.245 0 0 1-.365-.882"
            />
            <path
              d="M.088 2.16a1.248 1.248 0 0 1 2.13-.882l5.046 5.045 4.942-4.942a1.248 1.248 0 0 1 1.765 1.765L8.147 8.97a1.247 1.247 0 0 1-1.765 0L.454 3.042a1.244 1.244 0 0 1-.366-.882"
            />
          </g>
        </svg>
      </span>
      <li id="slide-01" tabindex="0">
        <p>
          Mutations can cause the flu strains in vaccines and the circulating
          flu strains to be <strong>different</strong>.
        </p>
      </li>
      <li id="slide-02" tabindex="0">
        <p>This is called <strong>Strain Mismatch</strong>.<sup>1,2</sup></p>
      </li>
      <li id="slide-03" tabindex="0">
        <p>There are <strong>two key reasons</strong> for strain mismatch.</p>
      </li>

      <li id="slide-04" tabindex="0">
        <p>
          One is <strong>antigenic drift</strong>, mutations to circulating flu
          strains that are <strong>unavoidable</strong>.<sup>1,2</sup>
        </p>
      </li>
      <li id="slide-05" tabindex="0">
        <p>The other is <strong>egg adaptation</strong>.<sup>1,2</sup></p>
      </li>
    </ul>
  </section>
</template>

<script>
import ScrollTrigger from "gsap/ScrollTrigger";
export default {
  name: "StrainMismatch",
  data: function () {
    return {
      activeSlide: 1,
      containerHeight: 0,
    };
  },
  computed: {
    formattedActiveSlide: function () {
      return this.activeSlide.toString().padStart(2, "0");
    },
    scrollHintAnimation: function () {
      return this.gsap
        .timeline({ paused: true, repeat: -1, repeatDelay: 3 })
        .to(this.$refs.scrollHint, { opacity: 1 })
        .fromTo(this.$refs.scrollHint, { y: -15 }, { y: 0, ease: "bounce" })
        .to(this.$refs.scrollHint, {
          opacity: 0,
          duration: 0.5,
          delay: 3,
        });
    },
    scrollHintHide: function () {
      return this.gsap.to(this.$refs.scrollHint, { opacity: 0 });
    },
  },
  methods: {
    initScrollHintListener: function () {
      const self = this;
      this.isScrolling = null;
      function isScrollingCallback() {
        if (!self.scrollHintAnimation.isPlaying) {
          self.scrollHintAnimation.restart();
        }
      }
      window.addEventListener(
        "scroll",
        function () {
          window.clearInterval(this.isScrolling);
          self.scrollHintAnimation.pause();
          this.isScrolling = null;
          this.isScrolling = window.setInterval(isScrollingCallback, 3000);
        },
        false
      );
    },
    initPanelAnimation: function () {
      const gsap = this.gsap;
      this.panels.forEach((panel, i) => {
        const panelTimeline = gsap.timeline({
          scrollTrigger: {
            start: `${i === 0 ? "center center" : "top bottom"}`,
            end: `${
              i === this.panels.length - 1
                ? "center center"
                : "bottom top+=175px"
            }`,
            directional: true,
            trigger: panel,
            scrub: true,
            fastScrollEnd: 5000,
          },
        });
        if (i > 0) {
          gsap.set(panel, { opacity: 0.1, scale: 0.9 });
          panelTimeline.to(panel, { opacity: 0.1, scale: 0.9 });
        }
        panelTimeline.to(panel, { opacity: 1, scale: 1 });
        if (i < this.panels.length - 1) {
          panelTimeline.to(panel, { opacity: 0.1, scale: 0.9 });
        } else {
          panelTimeline.to(panel, { opacity: 1, scale: 1 });
        }
      });
    },
    initParallaxAnimation: function () {
      this.containerHeight = this.$refs.strainMismatch.offsetHeight;
      this.gsap.timeline({
        scrollTrigger: {
          trigger: this.$refs.parallaxContainer,
          pinSpacing: true,
          end: `+=${this.containerHeight - this.$refs.parallaxContainer.offsetHeight}`,
          pin: true,
          onEnter: () => this.scrollHintHide.restart(),
          onLeaveBack: () => this.scrollHintHide.reverse(),
        },
      });
      this.gsap.to(".parallax-bg", {
        scrollTrigger: {
          scrub: true,
        },
        y: (i, target) =>
          ScrollTrigger.maxScroll(window) * target.dataset.speed,
        ease: "none",
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.containerHeight = this.gsap.getProperty(
        ".strain-mismatch",
        "height"
      );
      this.panels = this.gsap.utils.toArray(".content li");
      this.initPanelAnimation();
      window.setTimeout(this.initParallaxAnimation, 100);
      this.initScrollHintListener();
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(
          #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
            ((100vmin - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

.strain-mismatch {
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: top;
  position: relative;
  overflow: hidden;
  background-color: rgba(185, 185, 185, 1);
}

.parallax-container {
  background: radial-gradient(
    rgba(255, 255, 255, 1) 40%,
    rgba(185, 185, 185, 1)
  );
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;
  width: 100vw;
  height: 100vh;
  position: absolute;
  overflow: hidden;
  z-index: -1;
  will-change: transform;
}

.parallax-bg {
  position: absolute;
  bottom: 70%;
  left: 0%;
  width: 50vw;
  height: 58.72vw;
  max-width: 493px;
  max-height: 579px;
  transform: scale(1.25);
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
  background-image: url("../assets/images/egg1.webp");
  will-change: transform;
}

.parallax-bg--2 {
  transform: scale(0.75);
  bottom: 30%;
  left: auto;
  right: -5%;
  z-index: 0;
  background-image: url("../assets/images/egg2.webp");
}

.parallax-bg--3 {
  transform: scale(1);
  bottom: 80%;
  left: 33%;
  z-index: 0;
  background-image: url("../assets/images/egg3.webp");
}

.content {
  max-width: 700px;
  width: auto;
  margin: 0;

  @media screen and (min-width: 1024px) {
    width: 50%;
    margin: 0;
  }

  .scroll-hint {
    font-size: 24px;
    color: #ea181b;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    min-height: 75px;
    z-index: 1;
    font-weight: 400;
    margin: 50px auto;

    svg {
      margin-top: 10px;
    }
  }
}

.content li {
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 50vh;
  padding: calc(12.5vh + 125px) 30px 12.5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  text-transform: uppercase;

  p {
    font-family: "Campton-Book", sans-serif;
  }

  &:first-child {
    padding-top: calc(25vh);
  }

  &:last-child {
    padding-bottom: calc(37.5vh);
  }
}

#slide-02 strong,
#slide-03 strong,
#slide-05 strong {
  display: inline-block;
}

sup {
  font-size: 0.5em;
  line-height: 0;
}

strong {
  color: #eb181b;
  font-family: "Campton", sans-serif;
  font-weight: 600;
}

h2 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0;
  line-height: 1.16em;
  @include fluid-type(380px, 1200px, 30px, 48px);
}

.indicator {
  height: 100vh;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 22px;
  padding: 35px 30px 0 0;
  margin: 0 0 0 15px;

  @media screen and (min-width: 768px) {
    padding: 35px 60px 0 0;
  }
  .number {
    display: block;
    margin: 10px 0;
    font-size: 14px;
    color: #5d6f7b;
  }

  svg {
    mask-image: linear-gradient(
      transparent 0%,
      black 10%,
      black 90%,
      transparent 100%
    );
  }
}
</style>
