<template>
  <div id="app">
    <div v-if="showSticky" class="space-holder"></div>
    <TopNavigation
      v-if="!isMobile"
      :isSticky="showSticky"
      :activeId="idInView"
      ref="navigation"
    />
    <MobileNavigation
      v-else
      :isSticky="showSticky"
      :videoHeight="videoHeight"
    />

    <h1>Cookie Policy</h1>

    <!-- OneTrust Cookies Settings button start -->

    <button id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie Settings</button>

    <!-- OneTrust Cookies Settings button end -->

    <!-- OneTrust Cookies List start -->

    <div id="ot-sdk-cookie-policy"></div>

    <!-- OneTrust Cookies List end -->

    <RedFooter />
  </div>
</template>

<script>
import TopNavigation from "./components/TopNavigation.vue";
import MobileNavigation from "./components/MobileNavigation.vue";
import RedFooter from "./components/RedFooter.vue";


export default {
  name: "CookiePolicy",
  components: {
    TopNavigation,
    MobileNavigation,
    RedFooter,
  },
  data() {
    return {
      showSticky: false,
      isMobile: window.innerWidth < 768,
      idInView: "",
    };
  },
  methods: {
    windowWidthChecker() {
      return (this.isMobile = window.innerWidth < 768);
    },
  },
  mounted() {
    // ignore current scroll position on reload
    if (history.scrollRestoration) {
      history.scrollRestoration = "manual";
    }
    this.$nextTick(() => {
      if ( typeof Optanon !== 'undefined' ) {
        Optanon.initializeCookiePolicyHtml();
      }
      window.addEventListener("resize", this.windowWidthChecker);
      document.getElementById("logo").focus();
    });
    
  },
};
</script>

<style lang="scss" scoped>
#app {
  font-family: "Campton", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;
}

h1 {
  margin-top: 2em;
}

#ot-sdk-cookie-policy {
  max-width: 1400px;
  width: auto;
  padding: 2rem;
  margin: auto;
}
</style>
