<template>
  <div id="app">
    <div v-if="showSticky" class="space-holder"></div>
    <StickyCta :isSticky="showSticky" />
    <TopNavigation
      v-if="!isMobile"
      :isSticky="showSticky"
      :activeId="idInView"
      ref="navigation"
    />
    <MobileNavigation
      v-else
      :isSticky="showSticky"
      :videoHeight="videoHeight"
    />
    <!-- <VideoHero :isMobile="isMobile" /> -->
    <div class="hero-replacement">
      <div
        class="hero-replacement__content"
        :class="{
          visible: isVisibleMain,
      }">
        <p>Could the way flu vaccines are made <strong><br />impact the protection they offer your patients</strong>?</p>
    </div>

      <div
        class="hero-replacement__content"
        :class="{
          visible: isVisibleSecondary,
      }">
        <p>When choosing your flu vaccines,<strong><br />Think Outside the Shell™</strong></p>
      </div>
    </div>
    <StrainMismatch id="what" />
    <EggAdaptation id="why" :isMobile="isMobile" />
    <section>
      <!-- <ImpactSection id="impact" :isMobile="isMobile" /> -->
    </section>
    <RedFooter />
    <div id="preload"><img src="./assets/images/egg-bg.webp" /></div>
  </div>
</template>

<script>
import TopNavigation from "./components/TopNavigation.vue";
import MobileNavigation from "./components/MobileNavigation.vue";
import StickyCta from "./components/StickyCta.vue";
// import VideoHero from "./components/VideoHero.vue";
import StrainMismatch from "./components/StrainMismatch.vue";
import RedFooter from "./components/RedFooter.vue";
// import ImpactSection from "./components/ImpactSection.vue";

export default {
  name: "App",
  components: {
    TopNavigation,
    MobileNavigation,
    StickyCta,
    // VideoHero,
    StrainMismatch,
    EggAdaptation: () => import("./components/EggAdaptation.vue"),
    RedFooter,
    // ImpactSection,
  },
  data() {
    return {
      showSticky: false,
      isMobile: window.innerWidth < 768,
      idInView: "",
      videoHeight: 0,
      isVisibleMain: true,
      isVisibleSecondary: false,
    };
  },
  methods: {
    // modalCheck(event) {
    //   this.modalIsOpen = event;

    //   if (this.modalIsOpen) {
    //     document.body.className = "no-overflow";
    //     document.getElementById("alertModal").focus();
    //   } else {
    //     document.getElementById("ctaBtn").focus();
    //     document.body.className = "";
    //   }
    // },
    toggleVisibility() {
      setTimeout(() => {
        this.isVisibleMain = !this.isVisibleMain;
      }, 3000); 
      this.startTimer();
    },
    startTimer() {
      clearTimeout(this.timer);
      setTimeout(() => {
        this.isVisibleSecondary = !this.isVisibleSecondary;
      }, 3500); 
    },
    hasUserScrolled() {
      const bgHero = document.querySelector(".hero-replacement")?.getBoundingClientRect();

      if (bgHero && window.pageYOffset >= bgHero.height + 175) {
        this.showSticky = true;
      } else {
        this.showSticky = false;
      }
    },
    windowWidthChecker() {
      return (this.isMobile = window.innerWidth < 768);
    },
    checkViewportElement() {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(({ target, isIntersecting }) => {
          if (
            target.getAttribute("id") !== "app" &&
            !target.getAttribute("id").includes("onetrust") &&
            isIntersecting
          ) {
            const rect = target.getBoundingClientRect();
            if (
              rect.top + rect.height / 2 > 0 &&
              rect.top + rect.height / 2 <
                (window.innerHeight || document.documentElement.clientHeight) &&
              rect.y !== rect.height * -1
            ) {
              this.idInView = target.getAttribute("id");
            }
          }
        });
      });

      // Reset idInView if at the top of the page
      if (window.scrollY === 0) this.idInView = "";

      const elemsWithIds = document.querySelectorAll("*[id]");
      elemsWithIds.forEach((elem) => observer.observe(elem));
    },
  },
  mounted() {
    // ignore current scroll position on reload
    if (history.scrollRestoration) {
      history.scrollRestoration = "manual";
    }
    this.$nextTick(() => {
      // const video = document.querySelector(".video").getBoundingClientRect();
      // this.videoHeight = video.height;
      window.addEventListener("scroll", this.hasUserScrolled);
      window.addEventListener("resize", this.windowWidthChecker);
      window.addEventListener("scroll", this.checkViewportElement);

      document.getElementById("logo").focus();
    });

    this.$nextTick(() => {
      this.toggleVisibility()
    })
  },
};
</script>

<style lang="scss" scoped>

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(
          #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
            ((100vmin - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

#app {
  font-family: "Campton", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;
}

section {
  background: radial-gradient(
    61.21% 61.21% at 36.75% 47.76%,
    rgba(166, 166, 166, 0.69) 0%,
    rgba(240, 240, 240, 0.69) 100%
  );
}

.space-holder {
  height: 125px;

  @media screen and (min-width: 768px) {
    height: 150px;
  }
}

#preload {
  display: none;
}

.hero-replacement {
  position: relative;
  background-image: url('../src/assets/images/hero_header_image.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: calc(100vh - 100px);
  text-transform: uppercase;

  &__content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 24px;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: opacity 500ms ease-in-out;
    box-sizing: border-box;

    p {
      display: inline-block;
      margin: 0;
      padding: 20px;
      line-height: 1.16em;
      @include fluid-type(380px, 1200px, 30px, 48px);

      strong {
        color: #eb181b;
        font-family: "Campton", sans-serif;
        font-weight: 600;
      }
    }

    &.visible {
      opacity: 1;
    }
  }

  @media screen and (min-width: 768px) {
    height: calc(100vh - 149.5px);
  }
}

</style>
