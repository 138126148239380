<template>
  <div class="sticky-cta" :class="{ sticky: isSticky }">
    <a href="https://www.flu360.com/products/flucelvax">
      <span>View a Flu Vaccine <br />Made Differently</span>
      <img
        alt="Click to open new page"
        src="../assets/images/chevron.svg"
      />
    </a>
  </div>
</template>

<script>
export default {
  name: "StickyCta",
  props: {
    isSticky: {
      Type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.sticky-cta {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: -150px;
  width: 180px;
  border: solid #ea181b;
  border-width: 4px 0 4px 4px;
  border-radius: 5px 0 0 5px;
  background-color: white;
  color: #ea181b;
  z-index: 9;
  text-align: center;
  pointer-events: none;
  opacity: 0;
  transition: all 0.3s ease-in-out;

  @media screen and (min-width: 500px) {
    width: 220px;
  }

  @media screen and (min-width: 769px) {
    width: 245px;
  }

  &.sticky {
    right: 0;
    opacity: 1;
    pointer-events: all;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ea181b;
    font-weight: bold;
    text-decoration: none;
    padding: 8px;
    font-size: 13px;

    @media screen and (min-width: 500px) {
      font-size: 14px;
      padding: 16px;
    }

    @media screen and (min-width: 769px) {
      font-size: 16px;
    }

    &:hover {
      text-decoration: underline;
    }

    > span {
      display: block;
      margin-right: 18px;
    }

    > img {
      display: block;
      height: 16px;
      width: auto;
      
      @media screen and (min-width: 500px) {
        height: 20px;
      }

      @media screen and (min-width: 769px) {
        height: 24px;
      }
    }
  }
}
</style>
