<template>
  <nav
    class="mobile-navigation"
    :class="{
      sticky: isSticky,
      'no-scroll': toggleNav,
      'off-screen':
        scrollDistance > 150 && scrollDistance < videoHeight + scrollDistance,
    }"
  >
    <a href="#">
      <img
        id="logo"
        alt="The logo for Seqirus"
        class="logo"
        src="../assets/images/Seqirus_Logo.png"
      />
    </a>

    <img
      alt="Click to open mobile menu"
      class="hamburger"
      src="../assets/images/hamburger.svg"
      @click="toggleMenu"
      @keyup.enter="toggleMenu"
      tabindex="0"
    />

    <div class="mobile-menu" :class="{ active: toggleNav }">
      <img
        alt="Click to close mobile menu"
        class="close-button"
        src="../assets/images/close.svg"
        @click="toggleMenu"
        @keyup.enter="toggleMenu"
        tabindex="0"
      />
      <ul>
        <li>
          <a href="#what" @click="toggleMenu">What is Strain Mismatch?</a>
          <div class="divider" />
        </li>
        <li>
          <a href="#why" @click="toggleMenu">Why Egg Adaptation Occurs</a>
          <div class="divider" />
        </li>
        <li>
          <a href="#impact" @click="toggleMenu">Impact of Egg Adaptation</a>
          <div class="divider" />
        </li>
        <li>
          <a href="#cta" @click="toggleMenu">Consider a Different Vaccine</a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: "MobileNavigation",
  props: {
    isSticky: {
      Type: Boolean,
      default: false,
    },
    videoHeight: {
      Type: Number,
      default: 0,
    },
  },
  data() {
    return {
      toggleNav: false,
      scrollDistance: 0,
    };
  },
  methods: {
    toggleMenu() {
      this.toggleNav = !this.toggleNav;
      if (this.toggleNav) {
        document.body.className = "no-overflow";
      } else {
        document.body.className = "";
      }
    },
    scrollChecker: function () {
      return (this.scrollDistance = window.pageYOffset);
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.scrollChecker);
      window.addEventListener("hashchange", function () {
        window.scrollTo(window.scrollX, window.scrollY - 100);
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.mobile-navigation {
  display: flex;
  position: relative;
  background: rgba(255, 255, 255, 0.9);
  align-items: center;
  justify-content: space-between;
  transition: top 0.3s ease-in-out;
  z-index: 10;
  top: 0px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
  height: 100px;

  .logo {
    padding: 1rem 2rem;
    width: 140px;
  }

  #logo:focus {
    outline: none;
  }

  .hamburger {
    max-width: 45px;
    height: min-content;
    pointer-events: all;
    padding-right: 2rem;

    &:hover {
      cursor: pointer;
    }
  }

  .mobile-menu {
    margin-top: 0;
    padding-top: 8rem;
    position: fixed;
    z-index: 10;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100vh;
    background-color: #e0e3e5;
    transition: 0.3s;
    box-sizing: border-box;
    // overflow: hidden;
    transition: left 0.3s ease-in-out;

    ul {
      list-style-type: none;
      padding: 0;

      li {
        margin: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        a {
          text-decoration: none;
          color: #5d6f7b;
          font-weight: bold;
          font-size: 1.5rem;

          &:hover {
            color: #eb181b;
          }
        }

        .divider {
          height: 1px;
          background-color: #5d6f7b;
          width: 70%;
          margin-top: 3rem;
          margin-bottom: 1rem;
        }
      }
    }

    .close-button {
      position: absolute;
      top: 50px;
      right: 0;
      padding-right: 4.5rem;
      pointer-events: all;
      cursor: pointer;
    }

    &.active {
      left: 0;
    }
  }
}

.mobile-navigation.off-screen {
  top: -150px;
}

.mobile-navigation.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

//Overwrite the default if a browser has support for backdrop-filters
// @supports (backdrop-filter: blur(15px)) {
//   .top-navigation {
//     background: rgba(255, 255, 255, 0.15);
//     backdrop-filter: blur(15px);
//   }
// }
</style>
