<template>
  <nav class="top-navigation" :class="{ sticky: isSticky }">
    <a
      @click="changeRoute('app')"
      tabindex="0"
      href="/#app"
      >
      <img
        alt="Seqirus logo"
        src="../assets/images/Seqirus_Logo.png"
        tabindex="0"
        id="logo"
      />
    </a>
    <ul>
      <li>
        <a
          :class="{ active: activeId.includes('slide') }"
          @click="changeRoute('what')"
          tabindex="0"
          href="/#what"
          >What is <br />
          Strain Mismatch?</a
        >
      </li>
      <li>
        <a
          :class="{
            active:
              activeId.includes('lottie_element') ||
              activeId.includes('dots') ||
              activeId.includes('text'),
          }"
          @click="changeRoute('why')"
          tabindex="0"
          href="/#why"
          >Why Egg <br />
          Adaptation Occurs</a
        >
      </li>
      <li>
        <a
          :class="{ active: activeId === 'impact' }"
          @click="changeRoute('impact')"
          tabindex="0"
          href="/#impact"
          >Impact of <br />
          Egg Adaptation</a
        >
      </li>
      <li>
        <a
          :class="{ active: activeId === 'cta' }"
          @click="changeRoute('cta')"
          tabindex="0"
          href="/#cta"
          >Consider a <br />
          Different Vaccine</a
        >
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "TopNavigation",
  props: {
    isSticky: {
      Type: Boolean,
      default: false,
    },
    activeId: {
      Type: String,
      default: "",
    },
  },
  methods: {
    changeRoute(id) {
      event.preventDefault();
      if (this.$route.path !== '/') {
        this.$router.push('/', () => {
          setTimeout(() => {this.scrollToSection(id)}, 250)
        });
      } else {
        this.scrollToSection(id);
      }
    },
    scrollToSection(id) {
      const windowHeight = window.innerHeight * 0.15;

      if (id === "cta") {
        this.gsap.to(window, {
          scrollTo: {
            y: `#${id}`,
            offsetY: -windowHeight,
          },
        });
      } else if (id === "impact") {
        this.gsap.to(window, {
          scrollTo: {
            y: `#${id}`,
            offsetY: 150,
          },
        });
      } else {
        this.gsap.to(window, {
          duration: 1,
          scrollTo: { y: `#${id}`, offsetY: 0 },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.top-navigation {
  display: flex;
  background: rgba(255, 255, 255, 0.9);
  align-content: center;
  justify-content: space-between;
  top: -150px;
  transition: top 0.3s ease-in-out;
  z-index: 1;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
  max-height: 150px;

  #logo:focus {
    outline: none;
  }

  img {
    padding: 2rem 4.5rem;
  }

  ul {
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    padding-right: 4.5rem;

    li {
      margin: 2rem;

      a {
        text-decoration: none;
        color: #5d6f7b;
        font-family: "Campton", sans-serif;
        font-weight: 600;

        &:hover {
          color: #eb181b;
          text-decoration: underline;
          pointer-events: all;
          cursor: pointer;
        }

        &.active {
          color: #eb181b;
          text-decoration: underline;
        }
      }
    }
  }

  @media screen and (min-width: 769px) and (max-width: 880px) {
    img {
      padding: 2rem 2.5rem;
    }

    ul {
      padding-right: 2.5rem;

      li {
        margin: 1rem;
      }
    }
  }
}

.top-navigation.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

//Overwrite the default if a browser has support for backdrop-filters
// @supports (backdrop-filter: blur(15px)) {
//   .top-navigation {
//     background: rgba(255, 255, 255, 0.15);
//     backdrop-filter: blur(15px);
//   }
// }
</style>
